import React from "react"
import { BiMailSend } from 'react-icons/bi';
import { BiBuildingHouse } from 'react-icons/bi';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      email: "",
      message: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;

    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
        <BiBuildingHouse /> 3 rue Jean Louis Belloc 47000 AGEN <br />
        <BiMailSend /> <a href="mailto:jeremie.binet@gmail.com">jeremie.binet@gmail.com</a>
        </div>
        <div className="col-md-6">
          <form id="contact-form" onSubmit={this.handleSubmit} method="POST">
            <div className="form-group">
              <label for="email">Adresse Email</label>
              <input id="email" name="email" type="email" className="form-control" aria-describedby="emailHelp" value={this.state.email} onChange={this.handleInputChange} />
            </div>
            <div className="form-group">
              <label for="message">Message</label>
              <textarea id="message" name="message" className="form-control" rows="8" value={this.state.message} onChange={this.handleInputChange}></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Envoyer</button>
          </form>
        </div>
      </div>
    );
  }
}

export default Contact;