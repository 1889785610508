import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Markdown from 'react-markdown'

export default function Realisations() {
  const data = useStaticQuery(graphql`
  query RealisationsQuery {
    allRealisations {
    edges {
      node {
        desc
        titre
        lien
        image {
          data {
            full_url
          }
        }
      }
    }
  }
}
`)
  return (
        <div>
          {data.allRealisations.edges.map(item => (
            <div>
              <span>{item.node.titre}</span>
              <Markdown source={item.node.desc} />
              <img className="img-real" src={item.node.image.data.full_url} alt={item.node.titre} width="auto" height="auto"/>
              <br/><a href={item.node.lien}>{item.node.lien}</a>
            </div>
          ))}
        </div>
      )
}