import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { FcFlashOn, FcIdea } from 'react-icons/fc';

export default function Realisations() {
  const data = useStaticQuery(graphql`
  query CompetencesQuery1 {
    allCompetences1(filter: {type: {eq: "langage"}}) {
    edges {
      node {
        nom
        niveau
        originalId
      }
    }
  }
  allCompetences2(filter: {type: {eq: "framework"}}) {
    edges {
      node {
        nom
        niveau
        originalId
      }
    }
  }
  allCompetences3(filter: {type: {eq: "bdd"}}) {
    edges {
      node {
        nom
        niveau
        originalId
      }
    }
  }
  allCompetences4(filter: {type: {eq: "autres"}}) {
    edges {
      node {
        nom
        niveau
        originalId
      }
    }
  }
}
`)
  return (
        <div className="row">
          <div className="col-md-3 col-sm-6">
            Langages
          <ul>
              {data.allCompetences1.edges.map(item => (
                <li key={item.node.id}>
                  {item.node.niveau in [0,1,2] ? <FcFlashOn /> : <FcIdea /> }  {item.node.nom}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-3 col-sm-6">
            Frameworks
          <ul>
              {data.allCompetences2.edges.map(item => (
                <li key={item.node.id}>
                  {item.node.niveau in [0,1,2] ? <FcFlashOn /> : <FcIdea /> }  {item.node.nom}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-3 col-sm-6">
            BDD
          <ul>
              {data.allCompetences3.edges.map(item => (
                <li key={item.node.id}>
                  {item.node.niveau in [0,1,2] ? <FcFlashOn /> : <FcIdea /> }  {item.node.nom}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-3 col-sm-6">
            Autres
          <ul>
              {data.allCompetences4.edges.map(item => (
                <li key={item.node.id}>
                  {item.node.niveau in [0,1,2] ? <FcFlashOn /> : <FcIdea /> }  {item.node.nom}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )
}