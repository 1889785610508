import React from "react";
import { useStaticQuery, graphql } from 'gatsby'
import Markdown from 'react-markdown';

export default function Experiences() {
  const data = useStaticQuery(graphql`
  query ExperiencesQuery2 {
    allExperiences {
      edges {
        node {
          titre
          desc
        }
      }
    }
  }
`)
  return (
    <div>
          {data.allExperiences.edges.map(item => (
            <div>
              <span>{item.node.titre}</span>
              <p><Markdown source={item.node.desc} /></p>
            </div>
          ))}
        </div>
  )
}