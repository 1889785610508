import React from "react"
import { Link, animateScroll as scroll } from "react-scroll";
import logo from "../../static/logo.png"
import { FaBars } from 'react-icons/fa';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            menu: false
        };
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    };


    render() {
        const { menu } = this.state;
        return (
            <nav className="navbar navbar-expand-md fixed-top">
                <a class="icon" onClick={() => this.setState({ menu: !menu })}>
                    <FaBars />
                </a>
                <div style={{ display: (menu ? 'block' : 'none') }} className="collapse navbar-collapse" id="navbarCollapse" >
                    <img
                        src={logo}
                        className="nav-logo"
                        alt="Logo"
                        onClick={this.scrollToTop}
                        width="auto"
                        height="auto"
                    />
                    <ul className="navbar-nav mr-auto" >

                        <Link
                            activeClass="active"
                            to="intro"
                            spy={true}
                            smooth={true}
                            offset={-40}
                            duration={300}
                            onClick={() => this.setState({ menu: !menu })}
                        >Intro </Link>
                        <Link
                            activeClass="active"
                            to="competences"
                            spy={true}
                            smooth={true}
                            offset={-40}
                            duration={300}
                            onClick={() => this.setState({ menu: !menu })}
                        >Compétences </Link>
                        <Link
                            activeClass="active"
                            to="experiences"
                            spy={true}
                            smooth={true}
                            offset={-40}
                            duration={300}
                            onClick={() => this.setState({ menu: !menu })}
                        >Expériences </Link>
                        <Link
                            activeClass="active"
                            to="realisations"
                            spy={true}
                            smooth={true}
                            offset={-40}
                            duration={300}
                            onClick={() => this.setState({ menu: !menu })}
                        >Réalisations </Link>
                        <Link
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-40}
                            duration={300}
                            onClick={() => this.setState({ menu: !menu })}
                        >Contact </Link>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Header;