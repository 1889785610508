import React from "react"
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../components/Header';
import Competences from '../components/Competences';
import Intro from '../components/Intro';
import Contact from '../components/Contact';
import Experiences from '../components/Experiences';
import Footer from '../components/Footer';
import Realisations from '../components/Realisations';
import '../styles/index.css';
import {Helmet} from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Jeremie BINET Développeur Freelance</title>
          <meta name="description" content="Jérémie BINET développeur web freelance" />
          <meta name="keywords" content="Jérémie, BINET, web, freelance, développeur, fullstack, react, php" />
          <html lang="fr" />
        </Helmet>
      <header>
        <Header />
      </header>
      <div className="container">
        <div className="row fixedh" id="intro">
          <Intro />
        </div>
        <div className="row fixedh" id="competences">
          <div className="container">
            <div className="row"><h4>Compétences</h4></div>
            <Competences />
          </div>
        </div>
        <div className="row fixedh" id="experiences">
          <div className="container">
            <div className="row"><h4>Expériences</h4></div>
            <Experiences />
          </div>
        </div>
        <div className="row fixedh" id="realisations">
          <div className="container">
            <div className="row"><h4>Réalisations</h4></div>
            <Realisations />
          </div>
        </div>
        <div className="row fixedh" id="contact">
          <div className="container">
            <div className="row"><h4>Contact</h4></div>
            <Contact />
          </div>
        </div>
        <div className="row fixedh">
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default App;