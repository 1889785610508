import React from "react"
import image from "../../static/image.jpg"

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
    };
  }

  render() {
    return (
      <div>
        <h3>Jérémie BINET</h3>
        <div className="row">
          <div className="col-md-3">
            <img className="image_intro" alt="intro_img" src={image} width="auto" height="auto"/>
          </div>
          <div className="col-md-6">
            <p>Passionné par le web, dynamique et rigoureux, mes 10 années d'expérience m'ont permis de maîtriser tous les aspects du développement web.
              Je peux aujourd'hui mettre mes compétences au service des clients et proposer une prestation de qualité pour répondre à vos besoins.
          </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;